import React from 'react';
import Section from '../../../components/section';
import {PageHero} from '../../../components/page-hero';
import Layout from '../../../components/layout/layout';
import JobDetailsTable from '../../../components/jobDetails/jobDetailsTable';
import JobDescription from '../../../components/jobDetails/jobDescription';
import BackButton from '../../../components/backButton';
import {Translate} from '../../../components/translations';
import nodejs from '../../../img/jobs/nodejs.svg';

const SeniorNodeJS = () => {
  const vacancy = {
    title: <Translate name="CAREER_SENIOR_NODE_JS_TITLE" />,
    deadline: <Translate name="CAREER_JOB_DEADLINE" />,
    mailSubject: 'Senior Node.js Developer',
    description: <Translate name="CAREER_SENIOR_NODE_JS_DESCRIPTION" />,
    responsibilities: <Translate name="CAREER_SENIOR_NODE_JS_RESPONSIBILITIES" />,
    requiredQualifications: <Translate name="CAREER_SENIOR_NODE_JS_QUALIFICATIONS" />,
    tableData: [
      {
        key: 'work-type',
        icon: 'clock',
        title: <Translate name="CAREER_JOB_DETAILS_TABLE_WORK_TYPE_TITLE" />,
        description: <Translate name="CAREER_JOB_DETAILS_TABLE_WORK_TYPE_TEXT" />,
      },
      {
        key: 'location',
        icon: 'map-marker-alt',
        title: <Translate name="CAREER_JOB_DETAILS_TABLE_LOCATION_TITLE" />,
        description: <Translate name="CAREER_JOB_DETAILS_TABLE_LOCATION_TEXT" />,
      },
    ],
    image: <img src={nodejs} alt="Senior Node.js Developer" width="150px" />,
  };

  return (
    <Layout>
      <PageHero title="CAREER_PAGE_TITLE_SENIOR_NODE_JS" subtitle="CAREER_PAGE_SUBTITLE_SENIOR_NODE_JS" />
      <Section>
        <BackButton text="Back to Career" path="/career" />
        <h2 className="col-12">{vacancy.title}</h2>
        <div className="d-lg-none col-md-12">
          <JobDetailsTable vacancy={vacancy} />
        </div>
        <JobDescription vacancy={vacancy} />
        <div className="d-none d-lg-block col-lg-4">
          <JobDetailsTable vacancy={vacancy} />
        </div>
      </Section>
    </Layout>
  );
};

export default SeniorNodeJS;
